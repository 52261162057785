// Generated by Framer (2add0ca)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Clipboard from "https://framerusercontent.com/modules/Hj20QU19p80mpYsvesiZ/EqB2oGdmJ81urPOd5L66/Clipboard.js";
const ClipboardFonts = getFonts(Clipboard);

const enabledGestures = {O6SU4LZ0o: {hover: true}};

const cycleOrder = ["O6SU4LZ0o"];

const variantClassNames = {O6SU4LZ0o: "framer-v-qbsduf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "O6SU4LZ0o", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "O6SU4LZ0o", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2Ij5y", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-qbsduf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"O6SU4LZ0o"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({"O6SU4LZ0o-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1xujy55-container"} layoutDependency={layoutDependency} layoutId={"IgqEnXYuL-container"} transformTemplate={transformTemplate}><Clipboard borderRadius={8} bottomLeftRadius={8} bottomRightRadius={8} color={"rgb(0, 0, 0)"} content={""} fill={"rgb(255, 255, 255)"} font fontFamily={"Inter"} fontSize={16} fontWeight={500} height={"100%"} id={"IgqEnXYuL"} isMixedBorderRadius={false} label={"tanjimislam27@gmail.com"} layoutId={"IgqEnXYuL"} padding={15} paddingBottom={15} paddingLeft={15} paddingPerSide={false} paddingRight={15} paddingTop={15} topLeftRadius={8} topRightRadius={8} width={"100%"} {...addPropertyOverrides({"O6SU4LZ0o-hover": {color: "var(--token-69d0d919-5106-45a3-9073-8a95b5ccacb9, rgb(255, 90, 0)) /* {\"name\":\"orange\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2Ij5y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2Ij5y .framer-2as74 { display: block; }", ".framer-2Ij5y .framer-qbsduf { height: 49px; overflow: hidden; position: relative; width: 225px; }", ".framer-2Ij5y .framer-1xujy55-container { flex: none; height: auto; left: 50%; position: absolute; top: 49%; width: auto; }", ".framer-2Ij5y .framer-v-qbsduf .framer-qbsduf { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 225
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"z6wOADPYV":{"layout":["fixed","fixed"]}}}
 */
const FramerVV76z6OnP: React.ComponentType<Props> = withCSS(Component, css, "framer-2Ij5y") as typeof Component;
export default FramerVV76z6OnP;

FramerVV76z6OnP.displayName = "sjfhdhgf";

FramerVV76z6OnP.defaultProps = {height: 49, width: 225};

addFonts(FramerVV76z6OnP, [...ClipboardFonts])